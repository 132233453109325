<script setup>
// 박건규 에디터님의 요청으로 제작된 페이지입니다.
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import {onMounted, ref, watch} from "vue";
import api from "@/api/api";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});

const authStore = useAuthStore();
const router = useRouter();

const sourceLangText = ref('');
const targetLangText = ref('');
const sourceTextareaRef = ref(null);
const targetTextareaRef = ref(null);

const adjustHeight = () => {
  const el = sourceTextareaRef.value
  if (el) {
    el.style.height = 'auto'
    el.style.height = el.scrollHeight + 'px'
    targetTextareaRef.value.style.height = 'auto'
    targetTextareaRef.value.style.height = el.scrollHeight + 'px'
  }
}

async function translateText() {
  try {
    targetLangText.value = '번역중';
    const res = await api.post('/editor/test/translate_en_to_ko', sourceLangText.value, {
      baseURL: 'https://lxcd-server.tplinkdns.com'
    })
    let count = 0;
    const intervalId = setInterval(() => {
      if (count === 3) {
        targetLangText.value = '번역중';
        count = 0;
      } else {
        targetLangText.value += '.';
      }
    }, 500)

    if (res.data.result) {
      clearInterval(intervalId);
      targetLangText.value = res.data.translate;
    }
  } catch (e) {
    console.error(e);
  }
}

watch(sourceLangText, (newVal) => {
  if (newVal.length > 3000) {
    alert('3,000자를 초과하였습니다.');
    const diff = 3000 - newVal.length;
    sourceLangText.value = newVal.slice(0, diff);
  }
});

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({name: 'Login'});
  }
})
</script>

<template>
  <div id="textEditor">
    <h3 class="title">법률 문장 번역기</h3>
    <div class="editor_wrapper">
      <div class="container">
        <div class="language_name source_lang">
          <p>영어</p>
        </div>
        <div class="textarea_wrapper">
          <textarea ref="sourceTextareaRef" id="sourceText" placeholder="번역할 문장을 입력하세요." v-model="sourceLangText" @input="adjustHeight"></textarea>
          <div class="text_action_container">
            <p class="text_limit">{{ sourceLangText.length }} / 3000</p>
            <button class="btn size_36 primary" @click="translateText">
              <span>번역하기</span>
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="language_name target_lang">
          <p>한국어</p>
        </div>
        <div class="textarea_wrapper">
          <textarea ref="targetTextareaRef" id="translationText" v-model="targetLangText" placeholder="번역" readonly></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
