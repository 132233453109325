<script setup>
import { ref, reactive, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IClose from "@/assets/img/icon/i-close.vue";
import IChevron from "@/assets/img/icon/i-chevron.vue";
import { useAuthStore } from "@/store/auth";
import { useStore } from "@/store";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const store = useStore();
const currentPath = ref(route.path);
const modalOnOff = ref(false);
const modalData = reactive({
  type: '', // language, field, prompt, user
  title: '', // Modal header title
  isEdit: false,
});
const isModalActionAdd = ref(false); // false: edit mode , true: add mode
const tamseCodes = ["A-1", "A-2", "A-3", "B-1", "B-2", "B-3", "C-1", "C-2", "C-3"];

const flags = reactive({
  loading: false
});

const formData = reactive({
  // Language
  languageId: 0,
  languageName: '',
  languageParameter: '',
  languageCountType: false, // false: 단어, true: 자

  // Translation model
  engineId: 0,
  engineProviderNameId: authStore.userId,
  engineName: '',
  engineParameter: '',

  //Field
  fieldId: 0,
  fieldName: '',
  fieldParameter: '',

  // Prompt
  translationModelId: 0,
  tamseCode: '',
  promptField: '',
  promptName: '', // 셀렉트에서 선택한 이름
  userInputPromptName: '', // 유저가 직접 입력한 이름
  promptContents: ``,
  promptId: 0,
  promptTypeId: 1,

  //User
  userName: '',
  userRole: '',
  userID: '',
  userEmail: '',
  userUniqueId: 0,
  userPW: '',
});

const closeModal = () => {
  modalOnOff.value = false;
  modalData.type = '';
  modalData.title = '';
  modalData.isEdit = false;
  flags.loading = false;
}

async function setModalData(payload) {
  const titleArr = [
    { key: `language`, title: `언어 추가` },
    { key: `engine`, title: `번역 엔진 추가` },
    { key: `field`, title: `분야 추가` },
    { key: `prompt`, title: `프롬프트 추가` },
    { key: `polishedPrompt`, title: `윤문 프롬프트 추가` },
    { key: `user`, title: `사용자 추가` },
  ]

  modalData.type = payload.modalType;
  isModalActionAdd.value = payload.action;

  const modalTitle = titleArr.find(titleArr => titleArr.key === payload.modalType);
  modalData.title = modalTitle.title;

  switch (payload.modalType) {
    case 'language':
      formData.languageName = payload.name;
      formData.languageParameter = payload.parameter;
      formData.languageCountType = payload.countType;
      formData.languageId = payload.id;

      if (formData.languageName !== '' && formData.languageParameter !== '') {
        modalData.isEdit = true;
      }
      break;
    case 'engine':
      formData.engineId = payload.engineId;
      formData.engineName = payload.name;
      formData.engineParameter = payload.parameter;

      if (formData.engineName !== '' && formData.engineParameter !== '') {
        modalData.isEdit = true;
      }
      break;
    case 'field':
      formData.fieldId = payload.fieldId;
      formData.fieldName = payload.fieldName;
      formData.fieldParameter = payload.fieldParameter;

      if (formData.fieldName !== '' && formData.fieldParameter !== '') {
        modalData.isEdit = true;
      }
      break;
    case 'prompt':
      if (store.translationModelList.length === 0) {
        await store.getTranslationModelList();
      }

      if (store.fieldList.length === 0) {
        await store.getFieldList()
      }

      formData.translationModelId = payload.translationModelId;
      formData.tamseCode = payload.tamse;
      formData.promptField = payload.fieldId;
      formData.promptName = payload.name;
      formData.promptContents = payload.promptContent;
      formData.promptId = payload.promptId;
      formData.promptTypeId = 1;

      if (formData.translationModelId !== 0
        && formData.tamseCode !== ''
        && formData.promptField !== 0
        && formData.promptName !== 0
        && formData.promptContents !== '') {
        modalData.isEdit = true;
      }
      break;
    case 'polishedPrompt':
      if (store.translationModelList.length === 0) {
        await store.getTranslationModelList();
      }

      if (store.fieldList.length === 0) {
        await store.getFieldList()
      }

      formData.translationModelId = payload.translationModelId;
      formData.tamseCode = payload.tamse;
      formData.promptField = payload.fieldId;
      formData.promptName = payload.name;
      formData.promptContents = payload.promptContent;
      formData.promptId = payload.promptId;
      formData.promptTypeId = 2;

      if (formData.translationModelId !== 0
          && formData.tamseCode !== ''
          && formData.promptField !== 0
          && formData.promptName !== 0
          && formData.promptContents !== '') {
        modalData.isEdit = true;
      }
      break;
    case 'user':
      formData.userName = payload.name; // 이름
      formData.userRole = payload.role; // 권한
      formData.userID = payload.userId; // 아이디 (접속할 떄 쓰는)
      formData.userEmail = payload.email; // 이메일
      formData.userUniqueId = payload.uniqueId; // DB 상의 유저 id 숫자값
      formData.userPW = payload.password; // 비밀번호
      modalData.isEdit = payload.action;
      break;
  }

  modalOnOff.value = true;
}

async function createDataByModalType() {
  flags.loading = true;

  let isSuccess = false;
  let payload = {};

  switch (modalData.type) {
    case 'language':
      isSuccess = await store.createLanguage(formData.languageName, formData.languageParameter, formData.languageCountType);
      break;
    case 'engine':
      isSuccess = await store.createTranslationModel(formData.engineProviderNameId, formData.engineName, formData.engineParameter);
      break;
    case 'field':
      isSuccess = await store.createField(formData.fieldName, formData.fieldParameter);
      break;
    case 'prompt':
      payload = {
        tamseCode: formData.tamseCode,
        translationProjectFieldId: formData.promptField,
        name: formData.promptName,
        prompt: formData.promptContents,
        promptTypeId: 1
      }

      if (formData.promptName === 'default') {
        payload.name = formData.userInputPromptName;
      }

      if (formData.translationModelId !== 0) {
        payload.translationModelId = formData.translationModelId
      }

      isSuccess = await store.createPrompt(payload);
      if(isSuccess) {
        window.location.reload();
      }
      break;
    case 'polishedPrompt':
      const FIXED_TAMSE_CODE = 'A-1';
      const FIXED_TRANSLATION_PROJECT_FIELD_ID = 1;
      const FIXED_PROMPT_TYPE_ID = 2;
      const FIXED_TRANSLATION_MODEL_ID = 1;
      const FIXED_DOCUMENT_TYPE_ID = 1;

      payload = {
        tamseCode: FIXED_TAMSE_CODE,
        translationProjectFieldId: FIXED_TRANSLATION_PROJECT_FIELD_ID,
        promptTypeId: FIXED_PROMPT_TYPE_ID,
        translationModelId: FIXED_TRANSLATION_MODEL_ID,
        // documentTypeId: FIXED_DOCUMENT_TYPE_ID,
        name: formData.promptName,
        prompt: formData.promptContents,
      }

      if (formData.promptName === 'default') {
        payload.name = formData.userInputPromptName;
      }

      if (formData.translationModelId !== 0) {
        payload.translationModelId = FIXED_TRANSLATION_MODEL_ID
      }

      isSuccess = await store.createPrompt(payload);
      if(isSuccess) {
        window.location.reload();
      }
      break;
    case 'user':
      let setRoleVal;

      if (formData.userRole === 'ADMIN') {
        setRoleVal = 1;
      } else if (formData.userRole === 'PRO') {
        setRoleVal = 2;
      }

      payload = {
        name: formData.userID,
        role: setRoleVal,
        nickname: formData.userName,
        email: formData.userEmail,
        password: formData.userPW,
      }
      isSuccess = await store.createUser(payload);
      break;
    default:
      alert('데이터가 올바르지 않습니다.');
      break;
  }

  if (isSuccess) {
    setModalData({
      modalType: modalData.type,
    })
  }
  closeModal();
}

async function updateDataByModalType() {
  let isSuccess = false;
  let success1 = false;
  let success2 = false;
  let payload = {};
  flags.loading = true;

  switch (modalData.type) {
    case 'language':
      // isSuccess = await store.createLanguage(formData.languageName, formData.languageParameter, formData.languageCountType);
      break;
    case 'engine':
      payload = {
        translationModelId: formData.engineId,
        providerNameId: formData.engineProviderNameId,
        modelName: formData.engineName,
        parameter: formData.engineParameter
      }

      isSuccess = await store.updateTranslationModel(payload);
      break;
    case 'prompt':
      payload = {
        tamse_code	:	formData.tamseCode,
        translation_project_field_id	:	formData.promptField,
        prompt_type_id	:	1,
        translation_model_id	:	formData.translationModelId,
        // documentTypeId	:	1,
        name	:	formData.promptName === 'default' ? formData.userInputPromptName : formData.promptName,
        prompt	:	formData.promptContents,
      }

      isSuccess = await store.updatePromptData(payload, formData.promptId);
      if(isSuccess) {
        window.location.reload();
      }
      break;
    case 'polishedPrompt':
      // 윤문 프롬프트 생성용 고정값
      const FIXED_TAMSE_CODE = 'A-1';
      const FIXED_TRANSLATION_PROJECT_FIELD_ID = 1;
      const FIXED_PROMPT_TYPE_ID = 2;
      const FIXED_TRANSLATION_MODEL_ID = 1;
      const FIXED_DOCUMENT_TYPE_ID = 1;

      payload = {
        tamse_code	:	FIXED_TAMSE_CODE,
        translation_project_field_id	:	FIXED_TRANSLATION_PROJECT_FIELD_ID,
        prompt_type_id	:	FIXED_PROMPT_TYPE_ID,
        translation_model_id	:	FIXED_TRANSLATION_MODEL_ID,
        // documentTypeId	:	FIXED_DOCUMENT_TYPE_ID,
        name	:	formData.promptName === 'default' ? formData.userInputPromptName : formData.promptName,
        prompt	:	formData.promptContents,
      }

      isSuccess = await store.updatePromptData(payload, formData.promptId);
      if(isSuccess) {
        window.location.reload();
      }
      break;
    case 'user':
      payload = {
        role: formData.userRole,
        nickname: formData.userName,
        email: formData.userEmail,
      }

      success1 = await store.updateUserData(payload, formData.userUniqueId);

      if(formData.userPW !== '') {
        success2 = await store.updatePassword(formData.userUniqueId, formData.userPW);
      } else {
        success2 = true;
      }
      if(success1 && success2) {
        isSuccess = true;
      }

      isSuccess = success1 && success2


      break;
    default:
      alert('데이터가 올바르지 않습니다.');
      break;
  }

  if (isSuccess) {
    setModalData({
      modalType: modalData.type,
    })
    closeModal();
  }
}

async function deleteDataByModalType() {
  if (!confirm("정말로 삭제 하시겠습니까?")) {
    return;
  }

  let isSuccess = false;

  switch (modalData.type) {
    case 'language':
      isSuccess = await store.deleteLanguage(formData.languageId);
      break;
    case 'engine':
      isSuccess = await store.deleteTranslationModel(formData.engineId);
      break;
    case 'field':
      isSuccess = await store.deleteField(formData.fieldId);
      break;
    case 'prompt':
      isSuccess = await store.deletePrompt(formData.promptId);
      break;
    case 'polishedPrompt':
      isSuccess = await store.deletePrompt(formData.promptId);
      break;
    case 'user':
      isSuccess = await store.deleteUser(formData.userUniqueId);
      break;
    default:
      console.warn("지원되지 않는 삭제 유형:", modalData.type);
      break;
  }

  if (isSuccess) {
    closeModal();
    window.location.reload();
  }
}

const handleEscKey = (e) => { // ESC 키로 모달 끄기
  if (e.key === 'Escape') {
    closeModal();
  }
}

// Control body scroll by modalOnOff.
watch(modalOnOff, (newVal) => {
  document.body.style.overflow = newVal ? 'hidden' : 'auto';
});

// Update currentPath by changing route path
watch(() => route.path, (newPath) => {
  currentPath.value = newPath;
}
);

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({ name: 'Login' });
  }

  // Close the modal when user press the ESC key
  window.addEventListener("keydown", handleEscKey);
})

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscKey);
});

</script>

<template>
  <div id="adminPage">
    <nav class="admin_button_wrapper">
      <router-link class="btn size_24 background_2"
        :class="{ 'black_1': currentPath === '/admin/language' || currentPath === '/admin' }" to="/admin/language">언어 관리
      </router-link>
      <router-link class="btn size_24 background_2" :class="{ 'black_1': currentPath === '/admin/engine' }"
        to="/admin/engine">번역 엔진 관리
      </router-link>
      <router-link class="btn size_24 background_2" :class="{ 'black_1': currentPath === '/admin/field' }"
        to="/admin/field">분야 관리
      </router-link>
      <router-link class="btn size_24 background_2" :class="{ 'black_1': currentPath === '/admin/prompt' }"
        to="/admin/prompt">번역 프롬프트 관리
      </router-link>
      <router-link class="btn size_24 background_2" :class="{ 'black_1': currentPath === '/admin/polishing_prompt' }"
        to="/admin/polishing_prompt">윤문 프롬프트 관리
      </router-link>
      <router-link v-if="store.myData.role === 'ADMIN'" class="btn size_24 background_2"
        :class="{ 'black_1': currentPath === '/admin/user' }" to="/admin/user">사용자 관리
      </router-link>
    </nav>

    <router-view @setModalData="setModalData"></router-view>

    <div v-show="modalOnOff" class="modal_wrapper">
      <div class="modal_container"
        :class="{ 'type_1': modalData.type === 'language' || modalData.type === 'field' || modalData.type === 'engine', 'type_2': modalData.type === 'prompt' || modalData.type === 'polishedPrompt' || modalData.type === 'user' }">
        <div v-show="flags.loading" class="loader">
          <div class="loading"></div>
        </div>
        <div class="modal_header">
          <h3>{{ modalData.title }}</h3>
          <i-close class="icon size_20 grey_2 close-button" @click="closeModal" />
        </div>

        <div class="modal_body">
          <!--언어추가-->
          <form class="form_group" v-if="modalData.type === 'language'">
            <label for="language">언어명</label>
            <input type="text" v-model="formData.languageName" placeholder="언어명을 입력하세요." />
            <label for="languageParameter">파라미터값</label>
            <input type="text" v-model="formData.languageParameter" placeholder="파라미터값을 입력하세요." />
            <label for="languageCountType">자수 기준</label>

            <div class="radio_wrapper">
              <div class="radio_btn" :class="{ 'selected': !formData.languageCountType }"
                @click="formData.languageCountType = false">
                <input class="radio_input" type="radio" name="languageCountType" value="false"
                  v-model="formData.languageCountType" />
                <label for="using">단어</label>
              </div>
              <div class="radio_btn" :class="{ 'selected': formData.languageCountType }"
                @click="formData.languageCountType = true">
                <input class="radio_input" type="radio" name="languageCountType" value="true"
                  v-model="formData.languageCountType" />
                <label for="using">자</label>
              </div>
            </div>
          </form>

          <!--분야 추가-->
          <form class="form_group" v-else-if="modalData.type === 'field'">
            <label for="fieldName">분야명</label>
            <input id="fieldName" type="text" v-model="formData.fieldName" placeholder="언어명을 입력하세요." />
            <label for="fieldParameter">파라미터값</label>
            <input id="fieldParameter" type="text" v-model="formData.fieldParameter" placeholder="파라미터값을 입력하세요." />
          </form>

          <!--엔진 추가-->
          <form class="form_group" v-else-if="modalData.type === 'engine'">
            <label for="fieldName">엔진명</label>
            <input id="fieldName" type="text" v-model="formData.engineName" placeholder="언어명을 입력하세요." />
            <label for="fieldParameter">파라미터값</label>
            <input id="fieldParameter" type="text" v-model="formData.engineParameter" placeholder="파라미터값을 입력하세요." />
          </form>

          <!-- 번역, 윤문 프롬프트 추가-->
          <form class="form_group" v-else-if="modalData.type === 'prompt' || modalData.type === 'polishedPrompt'">
            <label v-show=" modalData.type === 'prompt' " for="translationModelId">번역 엔진</label>
            <select v-show=" modalData.type === 'prompt' " v-model="formData.translationModelId">
              <option value="0" disabled>번역 엔진 선택</option>
              <option v-for="tml in store.translationModelList" :key="tml" :value="tml.id">{{ tml.model_name }}</option>
              <i-chevron class="icon size_16 grey_3"></i-chevron>
            </select>

            <label v-show=" modalData.type === 'prompt' " for="tamseCode">대분류 <span>TAMSE</span></label>
            <select v-show=" modalData.type === 'prompt' " v-model="formData.tamseCode">
              <option value="" disabled>TAMSE 선택</option>
              <option v-for="tc in tamseCodes" :key="tc" :value="tc">{{ tc }}</option>
              <i-chevron class="icon size_16 grey_3"></i-chevron>
            </select>

            <label v-show=" modalData.type === 'prompt' " for="promptField">중분류 <span>분야</span></label>
            <select v-show=" modalData.type === 'prompt' " v-model="formData.promptField">
              <option value="0" disabled>분야 선택</option>
              <option v-for="fl in store.fieldList" :key="fl" :value="fl.id">{{ fl.name }}</option>
            </select>

            <label v-if="modalData.type === 'prompt'" for="promptName">프롬프트 <span>기업명</span></label>
            <label v-if="modalData.type === 'polishedPrompt'" for="promptName">윤문 프롬프트</label>
            <select v-model="formData.promptName">
              <option value="" disabled>프롬프트명을 선택하세요</option>
              <option v-if="modalData.type === 'prompt' " v-for="pl in store.promptList" :key="pl" :value="pl.name">{{ pl.name }}</option>
              <option v-else-if="modalData.type === 'polishedPrompt' " v-for="ppl in store.polishingPromptList" :key="ppl" :value="ppl.name">{{ ppl.name }}</option>
              <option value="default">직접 입력</option>
            </select>
            <input v-if="formData.promptName === 'default'" type="text" placeholder="프롬프트명을 입력하세요."
              v-model="formData.userInputPromptName" />

            <label for="promptContents">프롬프트 내용</label>
            <textarea v-model="formData.promptContents" placeholder="프롬프트 내용을 입력하세요"></textarea>
          </form>

          <!--사용자 추가-->
          <form class="form_group" v-else-if="modalData.type === 'user'">
            <label for="userName">이름</label>
            <input type="text" v-model="formData.userName" placeholder="이름을 입력하세요." />

            <label>계정 권한</label>
            <div class="radio_wrapper">
              <div class="radio_btn" :class="{ 'selected': formData.userRole === 'ADMIN' }"
                @click="formData.userRole = 'ADMIN'">
                <input class="radio_input" type="radio" name="userRoleAdmin" value="ADMIN"
                  v-model="formData.userRole" />
                <label for="userRole">관리자 계정</label>
              </div>
              <div class="radio_btn" :class="{ 'selected': formData.userRole === 'PRO' }"
                @click="formData.userRole = 'PRO'">
                <input class="radio_input" type="radio" name="userRolePro" value="PRO" v-model="formData.userRole" />
                <label for="userRolePro">일반 계정</label>
              </div>
            </div>

            <label for="userID">아이디</label>
            <input type="text" v-model="formData.userID" placeholder="아이디를 입력하세요." />

            <label for="userEmail">이메일</label>
            <input type="text" v-model="formData.userEmail" placeholder="이메일을 입력하세요." />

            <label for="userPW">비밀번호</label>
            <input type="password" v-model="formData.userPW" placeholder="비밀번호를 입력하세요." />
          </form>
        </div>

        <div class="modal-footer">
          <button v-if="!modalData.isEdit" class="btn size_28 primary" @click="createDataByModalType">저장</button>
          <button
            v-else-if="(modalData.isEdit && modalData.type === 'engine') || (modalData.isEdit && modalData.type === 'prompt') || (modalData.isEdit && modalData.type === 'polishedPrompt') || (modalData.isEdit && modalData.type === 'user')"
            class="btn size_28 primary" @click="updateDataByModalType()">수정
          </button>
          <button v-if="isModalActionAdd" class="btn size_28 white delete" @click="deleteDataByModalType">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
