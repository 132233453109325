<script setup>
import {onMounted, ref} from 'vue'
import IDownload from "@/assets/img/icon/i-download.vue";
import IChevron from "@/assets/img/icon/i-chevron.vue";
import {useStore} from "@/store";
import api from "@/api/api";
import {ENDPOINTS} from "@/api/constants";
import IFile from "@/assets/img/icon/i-file.vue";
import IEdit from "@/assets/img/icon/i-edit.vue";
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import IClose from "@/assets/img/icon/i-close.vue";
import ITerminal from "@/assets/img/icon/i-terminal.vue";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});
const authStore = useAuthStore();
const store = useStore();
const router = useRouter();

// Variables
const uploadedFile = ref(null);
const uploadedGlossaryFile = ref(null);
const fileInput = ref(null);
const glossaryFileInput = ref(null);
const isDragging = ref(false);
const fileUploadProgress = ref(0);
const detectedFileData = ref({
  name: '',
  size: '',
  data: {}
});
const detectedGlossaryFileData = ref({
  name: '',
  size: '',
  data: {}
});
const wordCountType = ref('');

const formData = ref({
  tamseCode: 'A-1',
  sourceLangId: 0,
  targetLangId: 0,
  publicPromptId: 1,
  translationModelId: 3,
  have_private_prompt: false,
  private_prompt: '',
  projectFieldId: 1,
  polishingPromptId: 2,
  displayName: '',
  documentTypeId: 35,
  promptContent: ''
})

const publicPromptList = ref([]);

const promptDataByCompany = [
  { name: '닥터마틴(chatGPT_4o)', tamseCode: 'B-2', fieldId: 4, translationModelId: 3, publicPromptId: 5 },
  { name: '닥터마틴(claude3.5_sonnet)', tamseCode: 'B-2', fieldId: 4, translationModelId: 6, publicPromptId: 6 },
  { name: '카카오엔터(chatgpt_4o)', tamseCode: 'A-2', fieldId: 4, translationModelId: 3, publicPromptId: 8 },
  { name: '카카오엔터(claude3.5_sonnet)', tamseCode: 'A-2', fieldId: 4, translationModelId: 6, publicPromptId: 9 },
  { name: '휴롬엘에스(chatgpt_4o)', tamseCode: 'B-2', fieldId: 19, translationModelId: 3, publicPromptId: 10 },
  { name: '휴롬엘에스(claude3.5_sonnet)', tamseCode: 'B-2', fieldId: 19, translationModelId: 6, publicPromptId: 11 },
  { name: '큐더스다임_회사소개서(chatgpt_4o)', tamseCode: 'C-2', fieldId: 5, translationModelId: 3, publicPromptId: 29 },
  { name: '큐더스다임_회사소개서(claude3.5Sonnet)', tamseCode: 'C-2', fieldId: 5, translationModelId: 6, publicPromptId: 30 },
];

const flags = ref({
  isFileUploaded: false,
  fileName: false,
  isGlossaryFileUploaded: false,
  glossaryFileName: false,
  creatingProject: false,
  hamburgerMenu: false,
  companyPromptMenu: false,
  selectedCompanyPromptNum: null
});

const usingCustomPrompt = ref('');

const timeoutId = ref(null);

// Functions
function onDragOver() {
  isDragging.value = true;
}

function onDragLeave() {
  isDragging.value = false;
}

function onDrop(event) {
  isDragging.value = false;
  uploadedFile.value = event.dataTransfer.files[0];

  const allowedExtensions = ['docx', 'xlsx', 'pptx', 'rtf'];
  const maxFileSize = 50 * 1024 * 1024;

  const fileExtension = uploadedFile.value.name.split('.').pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    alert('지원하지 않는 파일 형식입니다.');
    uploadedFile.value = null;
    return
  }

  if (uploadedFile.value.size > maxFileSize) {
    alert('파일의 크기가 50MB 보다 큽니다.');
    uploadedFile.value = null;
    return
  }

  handleFiles(uploadedFile.value);
}

function onGlossaryFileDrop(event) {
  isDragging.value = false;
  uploadedGlossaryFile.value = event.dataTransfer.files[0];
  handleFiles(uploadedGlossaryFile.value);
}

function onClick() {
  fileInput.value.click();
}

function onClickGlossary() {
  glossaryFileInput.value.click()
}

function onFileSelect(event) {
  const maxFileSizeMB = 50;

  if (event.target.files[0].size > maxFileSizeMB * 1024 * 1024) {
    alert(`파일 크기가 ${maxFileSizeMB}MB를 초과합니다. 다른 파일을 선택해주세요.`);
    return;
  }

  uploadedFile.value = event.target.files[0];
  handleFiles(uploadedFile.value);
}

function onGlossaryFileSelect(event) {
  const maxFileSizeMB = 50;

  if (event.target.files[0].size > maxFileSizeMB * 1024 * 1024) {
    alert(`파일 크기가 ${maxFileSizeMB}MB를 초과합니다. 다른 파일을 선택해주세요.`);
    return;
  }

  uploadedGlossaryFile.value = event.target.files[0];
  handleGlossaryFiles(uploadedGlossaryFile.value);
}

function cancelFileUpload() {
  fileInput.value = null;
  uploadedFile.value = null;
  isDragging.value = false;
  fileUploadProgress.value = 0;
  detectedFileData.value.name = '';
  detectedFileData.value.size = '';
  detectedFileData.value.data = {};
  flags.value.fileName = false;
  flags.value.isFileUploaded = false;
  usingCustomPrompt.value = '';
}

function cancelGlossaryFileUpload() {
  if (confirm("업로드한 용어집을 취소하시겠습니까?")) {
    detectedGlossaryFileData.value.name = '';
    detectedGlossaryFileData.value.size = '';
    detectedGlossaryFileData.value.data = {};
    flags.value.glossaryFileName = false;
    flags.value.isGlossaryFileUploaded = false;
  }
}

function modifyFileSize(fileSize) { // 파일 크기 출력. KB 단위 넘어가면 MB 리턴
  const fileSizeInKB = (fileSize / 1024).toFixed(2);
  const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

  let displaySize;

  if (fileSize < 1024 * 1024) {
    displaySize = `${fileSizeInKB} KB`; // 1MB 미만은 KB 단위로 표시
  } else {
    displaySize = `${fileSizeInMB} MB`; // 1MB 이상은 MB 단위로 표시
  }

  return displaySize;
}

function shortenFilename(fullName) {
  const lastDotIndex = fullName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fullName;
  }

  const fileName = fullName.slice(0, lastDotIndex);
  const extension = fullName.slice(lastDotIndex + 1);

  const shortenedFileName = fileName.slice(0, 10);
  return `${shortenedFileName}...${extension}`;
}

function checkFormData() {
  let result = false;
  const targetUpload = document.querySelector('#fileUpload');
  const targetLang = document.getElementById('tgtLang');
  const targetCustomPrompt = document.getElementsByClassName('radio_btn');
  const deniedAction = 'denied_action';

  if (!flags.value.isFileUploaded) {
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("파일을 업로드하세요.");

    targetUpload.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    setTimeout(() => {
      targetUpload.classList.add(deniedAction);
    }, 500)


    timeoutId.value = setTimeout(() => {
      targetUpload.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if (formData.value.targetLangId === formData.value.sourceLangId) { // 출발어 === 도착어
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("출발어와 다른 도착어를 선택하세요.");

    targetLang.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    targetLang.classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetLang.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if (formData.value.targetLangId === 0) { // 도착어 미선택
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("도착어를 선택하세요.");

    targetLang.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    targetLang.classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetLang.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if (usingCustomPrompt.value === '') { // 커스텀 프롬프트 미선택
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("커스텀 프롬프트 사용 여부를 선택하세요.");

    targetCustomPrompt[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    targetCustomPrompt[0].classList.add(deniedAction);
    targetCustomPrompt[1].classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetCustomPrompt[0].classList.remove(deniedAction);
      targetCustomPrompt[1].classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  return true;
}

async function handleCustomPrompt(yn) {
  if (flags.value.isFileUploaded) {
    usingCustomPrompt.value = yn;
  }

  if (yn === 'N') {
    formData.value.publicPromptId = 1;
    formData.value.documentTypeId = 35;
    return
  }

  const payload = {
    translationProjectFieldId: formData.value.projectFieldId,
    translationModelId: formData.value.translationModelId,
    tamseCode: formData.value.tamseCode,
    promptTypeId: 1,
  }

  if (usingCustomPrompt.value === 'Y') {
    await store.getDocumentTypeDefault(payload);
    await store.getDocumentTypeDefault();
  }
}

async function handleFiles(file) {
  detectedFileData.value.name = file.name;
  detectedFileData.value.size = modifyFileSize(file.size);
  formData.value.displayName = file.name;
  detectedFileData.value.data = await detectSourceLanguageAndWordCount(file);
}

async function handleGlossaryFiles(file) {
  detectedGlossaryFileData.value.name = file.name;
  detectedGlossaryFileData.value.size = modifyFileSize(file.size);
  flags.value.isGlossaryFileUploaded = true;
  flags.value.glossaryFileName = true;
}

async function detectSourceLanguageAndWordCount(fileData) {
  try {
    let file = new FormData();
    file.append('file', fileData);

    const res = await api.post(ENDPOINTS.DETECT_SOURCE_LANGUAGE_AND_WORD_COUNT, file, {
      onUploadProgress: (progressEvent) => {
        fileUploadProgress.value = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      },
    });

    if (res.data[0]) {
      flags.value.isFileUploaded = res.data[0];

      // language parameter -> id
      const temp = store.languageListDefault.find(lld => lld.parameter === res.data[1].source_language);
      formData.value.sourceLangId = temp.id;

      if (res.data[1].source_language === 'Korean' || res.data[1].source_language === 'Japanese' || res.data[1].source_language === 'Chinese') {
        wordCountType.value = '자';
      } else {
        wordCountType.value = '단어';
      }

      return res.data[1];
    } else {
      alert("잘못된 문서입니다.")
      cancelFileUpload();
    }

  } catch (e) {
    await store.handleApiError(e)
  }
}

async function createProject() {
  try {
    if (!checkFormData()) {
      return;
    }

    flags.value.creatingProject = true;

    let rb = new FormData();

    rb.append("tamse_code", formData.value.tamseCode);
    rb.append("project_field_id", formData.value.projectFieldId);
    rb.append("source_language_id", formData.value.sourceLangId);
    rb.append("target_language_id", formData.value.targetLangId);
    rb.append("public_prompt_id", formData.value.publicPromptId);

    if (usingCustomPrompt.value === 'Y') {
      rb.append("have_private_prompt", usingCustomPrompt.value === 'Y');
      rb.append("private_prompt", formData.value.promptContent);
      rb.append("document_type_id", formData.value.documentTypeId === 0 ? 35 : formData.value.documentTypeId);
    }

    rb.append("translation_model_id", formData.value.translationModelId);
    rb.append("request_sentence_range", 5);
    rb.append("display_name", formData.value.displayName);

    rb.append("file", uploadedFile.value);

    if (flags.value.isGlossaryFileUploaded) {
      rb.append("glossary_file", uploadedGlossaryFile.value);
    }

    if (store.myData.role === 'ADMIN' && formData.value.sourceLangId === 2 && formData.value.targetLangId === 1) {
      rb.append("public_polishing_prompt_id", formData.value.polishingPromptId)
    }


    const res = await api.post(ENDPOINTS.CREATE_PROJECT, rb);
    if (res.data.result) {
      const newProject = await store.getProjectList(1, 1);

      await router.push({
        name: 'ResultProject',
        query: {projectId: newProject.result[0].id},
      })
    }
  } catch (e) {
    console.error("create project", e);
    flags.value.creatingProject = false;
  }
}

async function getPublicPromptList() {
  if (usingCustomPrompt.value === 'N' || usingCustomPrompt.value === '') {
    return;
  }

  try {
    const payload = {
      translationProjectFieldId: formData.value.projectFieldId,
      translationModelId: formData.value.translationModelId,
      tamseCode: formData.value.tamseCode,
    }

    const isSuccess = await store.getPublicPromptListDefault(payload);

    if (isSuccess) {
      publicPromptList.value = store.publicPromptListDefault;
    }
  } catch (e) {
    console.error("get public prompt list error: ", e);
  }
}

async function generatePrompt() {
  try {
    if (formData.value.targetLangId === 0) {
      return
    }

    formData.value.promptContent = '';
    formData.value.publicPromptId = 1;

    const setQuery = `${ENDPOINTS.GENERATE_COMMON_PROMPT}?translation_project_field_id=${formData.value.projectFieldId}&tamse_code=${formData.value.tamseCode}&target_language_id=${formData.value.targetLangId}&document_type_id=${formData.value.documentTypeId}`;
    const res = await api.get(setQuery);

    if (res.data.result) {
      formData.value.promptContent = '';
      formData.value.promptContent = res.data.result;
    }

    const payload = {
      translationProjectFieldId: formData.value.projectFieldId,
      translationModelId: formData.value.translationModelId,
      promptTypeId: 1,
      tamseCode: formData.value.tamseCode,
    }

    await store.getPublicPromptListDefault(payload);
  } catch (e) {
    console.error("generatePrompt", e);
  }
}

function getPromptContentByCompany() {
  const temp = store.publicPromptListDefault.find(item => item.id === formData.value.publicPromptId);
  formData.value.documentTypeId = 0;
  formData.value.promptContent = '';
  formData.value.promptContent = temp.prompt;
}

async function initialize() {
  if (!authStore.isAuthenticated) {
    await router.push({name: 'Login'});
  }
  if (!!store.languageListDefault) {
    await store.getLanguageListDefault();
  }
  if (!!store.translationModelListDefault) {
    await store.getTranslationModelListDefault();
  }
  if (!!store.fieldListDefault) {
    await store.getFieldListDefault();
  }
  if (!!store.publicPromptListDefault) {
    const payload = {
      translationProjectFieldId: formData.value.projectFieldId,
      translationModelId: formData.value.translationModelId,
      promptTypeId: 1,
      tamseCode: formData.value.tamseCode,
    }
    await store.getPublicPromptListDefault(payload);
  }

  if (!!store.polishingPromptListDefault) {
    if (store.myData.role !== 'ADMIN') {
      return;
    }

    const payload = {
      translationProjectFieldId: 1,
      translationModelId: 1,
      promptTypeId: 2,
      tamseCode: 'A-1',
    }
    await store.getPublicPromptListDefault(payload);
  }
  if (!!store.documentTypeDefault) {
    await store.getDocumentTypeDefault();
  }
}

function handleHamburgerUi() {
  if(flags.value.hamburgerMenu) {
    flags.value.companyPromptMenu = false;
  }

  flags.value.hamburgerMenu = !flags.value.hamburgerMenu;
}

function handleFloatingMenu() {
  flags.value.companyPromptMenu = !flags.value.companyPromptMenu;
}

async function handlePromptDataTable(data, index) {
  const NOT_APPLICABLE = 35;

  usingCustomPrompt.value = 'Y';
  formData.value.documentTypeId = NOT_APPLICABLE;
  formData.value.tamseCode = data.tamseCode;
  formData.value.translationModelId = data.translationModelId;
  formData.value.projectFieldId = data.fieldId;
  flags.value.selectedCompanyPromptNum = index;

  setTimeout(() => {
    formData.value.publicPromptId = data.publicPromptId;
    flags.value.hamburgerMenu = !flags.value.hamburgerMenu;
    flags.value.companyPromptMenu = !flags.value.companyPromptMenu;
    getPromptContentByCompany();
    flags.value.selectedCompanyPromptNum = null;

    const promptTextArea = document.querySelector('#promptTextArea');

    promptTextArea.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, 1000)

  await generatePrompt();
}

onMounted(async () => {
  await initialize();
});
</script>
<template>
  <div id="createProject">
    <div class="create_project_wrapper">
      <h3 v-show="fileUploadProgress !== 100" class="title">생성형 AI 솔루션이 필요한 파일을 업로드 해주세요.</h3>
      <div v-show="fileUploadProgress === 100" class="file_uploaded_title_wrapper">
        <div v-if="!flags.fileName" class="file_uploaded_title" style="padding-bottom: 9.2px">
          <span class="title" style="text-align: start">{{ formData.displayName }}</span>
          <div class="edit_btn" @click="flags.fileName = true">
            <i-edit class="icon size_12 grey_2"/>
          </div>
        </div>

        <div v-else class="file_uploaded_title editing_mode">
          <input type="text" v-show="fileUploadProgress === 100" id="file_name_input" class="title"
                 v-model="formData.displayName" placeholder="프로젝트명을 입력하세요."/>
          <span class="edit_confirm_btn" @click="flags.fileName = false">확인</span>
        </div>
      </div>

      <div v-show="fileUploadProgress !== 100" id="fileUpload" @dragover.prevent="onDragOver" @dragleave="onDragLeave"
           @drop.prevent="onDrop" @click="onClick">
        <input type="file" ref="fileInput" @change="onFileSelect" style="display: none;"
               accept=".docx, .xlsx, .pptx, .rtf"/>
        <div class="upload_contents">
          <img src="@/assets/img/upload_cloud.png" alt="upload cloud"/>

          <p>drag & drop here</p>
          <p>최대 파일 첨부 크기는 50MB 입니다.</p>
          <p>docx, xlsx, pptx, rtf 형식만 지원 가능합니다.</p>

          <button class="btn size_21 white">파일 직접 선택하기</button>
        </div>
      </div>

      <div v-show="fileUploadProgress === 100" class="file_uploaded_container">
        <i-file class="icon size_20 blue"/>
        <span class="file_name">{{ detectedFileData.name }}</span>
        <div class="file_sub_info">
          <p class="file_size">{{ detectedFileData.size }}</p>
          <p v-if="detectedFileData?.data?.string_count" class="file_word_count">{{
              detectedFileData?.data?.string_count && wordCountType ? `${detectedFileData.data.string_count} ${wordCountType}` : ''
            }}</p>
          <p v-else class="loading" style="width: 11px; height: 11px; padding: 2px;"></p>
        </div>
        <i-close class="icon size_12 grey_3 cancel_file_upload" @click="cancelFileUpload"/>
      </div>

      <h6>설정</h6>
      <div class="setting_wrapper">
        <div class="settings_container">
          <!--출발어-->
          <div class="setting_item">
            <label for="srcLang"><strong>*</strong>출발어</label>
            <div class="select_container">
              <select id="srcLang" v-model="formData.sourceLangId" :disabled="!flags.isFileUploaded">
                <option value="0" disabled>자동인식</option>
                <option v-for="lld in store.languageListDefault" :key="lld" :value="lld.id">{{ lld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--도착어-->
          <div class="setting_item">
            <label for="tgtLang"><strong>*</strong>도착어</label>
            <div class="select_container">
              <select id="tgtLang" v-model="formData.targetLangId" :disabled="!flags.isFileUploaded"
                      @change="generatePrompt">
                <option value="0" disabled>선택</option>
                <option v-for="lld in store.languageListDefault" :key="lld" :value="lld.id">{{ lld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--용어집-->
          <div class="setting_item">
            <div class="glo_title">
              <label>용어집</label>
              <a href="/sample_file/sample_glossary.xlsx" class="btn size_16 grey">
                <span>샘플</span>
                <i-download class="icon size_16 grey_3"/>
              </a>
            </div>
            <button v-show="!flags.isGlossaryFileUploaded" :disabled="!flags.isFileUploaded" class="btn size_18 blue"
                    id="glossaryFileUpload" @dragover.prevent="onDragOver" @dragleave="onDragLeave"
                    @drop.prevent="onGlossaryFileDrop" @click="onClickGlossary">
              <input type="file" ref="glossaryFileInput" @change="onGlossaryFileSelect" style="display: none;"/>
              + 첨부하기
            </button>
            <button v-show="flags.isGlossaryFileUploaded" class="btn size_18 blue glo_file_name"
                    @click="cancelGlossaryFileUpload">
              {{ shortenFilename(detectedGlossaryFileData.name) }}
              <i-close class="icon size_12 blue"/>
            </button>
          </div>

          <!--TAMSE-->
          <div class="setting_item">
            <label for="tamse"><strong>*</strong>TAMSE</label>
            <div class="select_container">
              <select id="tamse" :disabled="!flags.isFileUploaded" v-model="formData.tamseCode"
                      @change="generatePrompt">
                <option value="" disabled>선택</option>
                <option value="A-1">A1</option>
                <option value="A-2">A2</option>
                <option value="A-3">A3</option>
                <option value="B-1">B1</option>
                <option value="B-2">B2</option>
                <option value="B-3">B3</option>
                <option value="C-1">C1</option>
                <option value="C-2">C2</option>
                <option value="C-3">C3</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--번역 엔진-->
          <div class="setting_item">
            <label for="engine"><strong>*</strong>번역 엔진</label>
            <div class="select_container">
              <select id="engine" :disabled="!flags.isFileUploaded" v-model="formData.translationModelId"
                      @change="getPublicPromptList">
                <option value="" disabled>선택</option>
                <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">{{
                    tmld.model_name
                  }}
                </option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--커스텀 프롬프트-->
          <div class="setting_item">
            <label><strong>*</strong>커스텀 프롬프트</label>
            <div class="select_container">
              <div class="radio_btn" :class="{ 'selected': usingCustomPrompt === 'Y' }"
                   @click="handleCustomPrompt('Y')">
                <input class="radio_input" type="radio" name="using" value="Y" v-model="usingCustomPrompt"
                       :disabled="!flags.isFileUploaded"/>
                <label for="using">사용</label>
              </div>
              <div class="radio_btn" :class="{ 'selected': usingCustomPrompt === 'N' }"
                   @click="handleCustomPrompt('N')">
                <input class="radio_input" type="radio" name="using" value="N" v-model="usingCustomPrompt"
                       :disabled="!flags.isFileUploaded"/>
                <label for="using">미사용</label>
              </div>
            </div>
          </div>

          <!--윤문 프롬프트-->
          <div v-if=" store.myData.role === 'ADMIN' && formData.sourceLangId === 2 && formData.targetLangId === 1 "
               class="setting_item">
            <label for="polishingPromptId">윤문 프롬프트</label>
            <div class="select_container">
              <select id="polishingPromptId" :disabled="!flags.isFileUploaded" v-model="formData.polishingPromptId">
                <option value="0" disabled>선택</option>
                <option v-for="ppld in store.polishingPromptListDefault" :key="ppld" :value="ppld.id">{{ ppld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--전체 분야-->
          <div v-show="usingCustomPrompt === 'N'" class="setting_item">
            <label for="allField"><strong>*</strong>전체 분야</label>
            <div class="select_container">
              <select id="allField" v-model="formData.projectFieldId">
                <option value="" disabled>선택</option>
                <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>
        </div>
      </div>

      <h6 v-if="usingCustomPrompt === 'Y'">커스텀 프롬프트</h6>
      <div v-if="usingCustomPrompt === 'Y'" class="custom_prompt">
        <div class="settings_container">

          <!--분야-->
          <div class="setting_item">
            <label for="field"><strong>*</strong>분야</label>
            <div class="select_container">
              <select id="field" v-model="formData.projectFieldId" @change="generatePrompt">
                <option value="" disabled>선택</option>
                <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--문서 타입-->
          <div class="setting_item">
            <label for="documentType"><strong>*</strong>문서 타입</label>
            <div class="select_container">
              <select id="documentType" v-model="formData.documentTypeId" @change="generatePrompt">
                <option value="0">사용 안 함</option>
                <option v-for="dtd in store.documentTypeDefault" :key="dtd" :value="dtd.id">{{ dtd.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--프롬프트(기업명)-->
          <div class="setting_item">
            <label for="prompt"><strong>*</strong>프롬프트(기업명)</label>
            <div class="select_container">
              <select id="prompt" v-model="formData.publicPromptId" @change="getPromptContentByCompany">
                <option value="1">사용 안 함</option>
                <option v-for="cpl in store.publicPromptListDefault" :key="cpl" :value="cpl.id">{{ cpl.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--프롬프트 내용-->
          <div class="setting_item prompt_text_area">
            <label for="promptTextArea">프롬프트 내용</label>
            <textarea id="promptTextArea" v-model="formData.promptContent"
                      placeholder="도착어를 선택하고 프롬프트를 완성하세요."></textarea>
          </div>
        </div>

      </div>

      <button class="btn size_36 primary editing_btn" :class="{ 'creating': flags.creatingProject }"
              @click="createProject">
        <span v-if="!flags.creatingProject">에디팅 하기</span>
        <span v-else class="loader">
          <span class="loading" style="width: 16px;"></span>
        </span>
      </button>
    </div>

    <!--업체별 프롬프트 플로팅 UI-->
    <div v-show=" formData.targetLangId !== 0 " class="floating_wrapper">
      <div class="floating_circle" @click=" handleHamburgerUi ">
        <div class="line_container" :class="{ 'active' : flags.hamburgerMenu}">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      <div class="floating_menu" :class="{ 'active' : flags.hamburgerMenu }" @click=" handleFloatingMenu ">
        <i-terminal class="icon size_32 black_1"/>
        <div class="company_prompt_container" :class="{ 'active' : flags.hamburgerMenu }">
          <p v-if="!flags.companyPromptMenu" class="title">기업별 프롬프트 선택</p>
          <div v-else class="table">
            <div v-for="(data, index) in promptDataByCompany" class="item" @click.stop="handlePromptDataTable(data, index)">
              <p v-if=" flags.selectedCompanyPromptNum === index " class="loading" style="width: 11px; height: 11px; padding: 2px; margin: 0 auto"></p>
              <p v-else class="text">{{ data.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="flags.hamburgerMenu" class="dim"></div>
    </div>
  </div>
</template>
