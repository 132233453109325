<script setup>
import IChevron from "@/assets/img/icon/i-chevron.vue";
import ITrash from "@/assets/img/icon/i-trash.vue";
import IDownload from "@/assets/img/icon/i-download.vue";
import ISetting from "@/assets/img/icon/i-setting.vue";
import ICopy from "@/assets/img/icon/i-copy.vue";
import ISynchronize from "@/assets/img/icon/i-synchronize.vue";
import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import api from "@/api/api";
import {ENDPOINTS} from "@/api/constants";
import IClose from "@/assets/img/icon/i-close.vue";
import IVariant from "@/assets/img/icon/i-variant.vue";
import IParaphrase from "@/assets/img/icon/i-paraphrase.vue";
import ICheck from "@/assets/img/icon/i-check.vue";
import ISearch from "@/assets/img/icon/i-search.vue";

const props = defineProps({
  window: {
    type: Object,
    required: false
  }
})

const authStore = useAuthStore();
const store = useStore();
const route = useRoute();
const router = useRouter();

// Variables
const projectBasicInfo = ref({});
const refinedProjectData = ref({
  sourceLang: '',
  targetLang: '',
  field: '',
  translationModel: ''
})

const currentProgressNum = ref(0);

const sentences = ref([]); // API 데이터를 저장
const tree = ref({});
let copiedTree = ref({});
const compareTree = ref({});

const projectText = ref([]); // 원문, 번역문, 역번역문 조합 / copiedProjectText 의 깊은 복사 대상
const copiedProjectText = ref([]); // projectText 복사본. 문장의 변화나 저장 여부 감지를 위한 배열
const compareProjectText = ref([]); // 비교 번역문
const projectTextInput = ref('');

const filteredProjectText = computed(() => {
  if (projectText.value.length < 1) {
    return false;
  }

  return projectText.value.filter(item => {
    setInitialHeights();
    return ((item.concatenatedOriginalText || '').toLowerCase().includes(projectTextInput.value.toLowerCase()) ||
        (item.concatenatedLatestText || '').toLowerCase().includes(projectTextInput.value.toLowerCase()));
  });
})

const compareEngineId = ref(0);

const timeoutId = ref(null);

const glossaryData = ref([]);
const copiedGlossaryData = ref([]);
const gloTextInput = ref('');
const filteredGlossaryData = computed(() => {
  if (glossaryData.value.length < 1) {
    return false
  }

  return glossaryData.value.filter(item => {
    setInitialHeights();
    return (
        (item.original_term || '').toLowerCase().includes(gloTextInput.value.toLowerCase()) ||
        (item.translated_term || '').toLowerCase().includes(gloTextInput.value.toLowerCase())
    );
  });
},)

const projectId = route.query.projectId;

const textareaRefs = ref([]);
const gloTextareaRefs = ref([]);
const gloUpdateId = ref(0);
const copiedGloIndex = ref(null);
let gloCopyTimeout = ref(null);

const lastGloId = ref(1);
const gloObserverTarget = ref(null);
let gloObserver;

const flags = ref({
  loadingTexts: false,
  loadingGlossary: false,
  deleteProject: false,
  cancelTranslating: false,
  downloadHover: false,
  compareEngineOnOff: false,
  selectCompareEngine: false,
  loadingCompareEngine: false,
  translationPartial: null,
  loadingTranslationPartial: false,
  tooltipHover: false,
  isGloFocused: null,
  applyEditedGlossaryData: false,
  applyEditedGlossaryDataHovered: false,
  savingGlo: -1,
  modalOnOff: false,
  paraphraseAll: false,
  paraphraseAllTooltip: false,
  paraphraseCopy: null,
  paraphraseCopyTooltip: null,
  translateAgain: false
})

const formData = ref({
  tamseCode: 'A-1',
  sourceLangId: 0,
  targetLangId: 0,
  publicPromptId: 1,
  translationModelId: 3,
  have_private_prompt: false,
  private_prompt: '',
  projectFieldId: 1,
  displayName: '',
  documentType: '',
  promptMode: 'segment',
  promptName: '',
  promptContents: ''
})

// Functions
function autoResize(pt) {
  nextTick(() => {
    const index = projectText.value.findIndex((item) => item === pt);
    const textarea = textareaRefs.value[index];

    if (textarea) {
      textarea.style.height = "auto"; // 높이 초기화
      textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞춰 높이 조정
    }
  });
}

function autoResizeGlo(gd) {
  nextTick(() => {
    const index = glossaryData.value.findIndex((item) => item === gd);
    const textarea = gloTextareaRefs.value[index];

    if (textarea) {
      textarea.style.height = "auto"; // 높이 초기화
      textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞춰 높이 조정
    }
  });
}

function findLanguageName(id) {
  const temp = store.languageListDefault.find(item => item.id === id);
  return temp.name;
}

function findFieldName(id) {
  const temp = store.fieldListDefault.find(item => item.id === id);
  return temp.name;
}

function findTranslationModelName(id) {
  const temp = store.translationModelListDefault.find(item => item.id === id);
  return temp.model_name;

}

///////
// 원문, 번역문, 역번역문 트리
const buildTree = () => {
  const treeData = {};

  sentences.value.forEach((item) => {
    const mainId = item.position_main_id;
    const subId = item.position_sub_id;

    if (!treeData[mainId]) {
      treeData[mainId] = {};
    }

    if (!treeData[mainId][subId]) {
      treeData[mainId][subId] = {
        sentences: [],
        concatenatedOriginalText: "",
        concatenatedTranslatedText: "",
        concatenatedTranslatedTextKorean: "",
        concatenatedLatestText: "",

        isHovered: false, // hover 상태 관리
        isTooltipVisible: false, // tooltip 상태 관리
        loading: false, // 로딩 상태 관리

        isNotSaved: false,
      };
    }

    treeData[mainId][subId].sentences.push(item);
  });

  Object.keys(treeData).forEach((mainId) => {
    Object.keys(treeData[mainId]).forEach((subId) => {
      const subNode = treeData[mainId][subId];
      subNode.sentences.sort((a, b) => a.sentence_id - b.sentence_id);

      // 원본 텍스트 합치기 (중복 제거)
      subNode.concatenatedOriginalText = [...new Set(subNode.sentences
          .map((sentence) => sentence.original_text))].join("");

      // 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedText = [...new Set(subNode.sentences
          .map((sentence) => sentence.translated_text))].join("");

      // 윤문 텍스트 합치기 (중복 제거)
      subNode.concatenatedPolishedText = [...new Set(subNode.sentences
          .map((sentence) => sentence.polished_text))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedTextKorean = [...new Set(subNode.sentences
          .map((sentence) => sentence.translated_text_korean))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedLatestText = [...new Set(subNode.sentences
          .map((sentence) => sentence.latest_text))].join("");

      if (subNode.concatenatedLatestText === '') {
        subNode.concatenatedLatestText = subNode.concatenatedTranslatedText;
      }
    });
  });
  return treeData;
};

// 트리 데이터 추출
function extractData(tree, target) {
  const result = [];

  // 각 mainId에 대해 반복
  Object.keys(tree).forEach((mainId) => {
    const mainNode = tree[mainId];

    // 각 subId에 대해 반복
    Object.keys(mainNode).forEach((subId) => {
      const subNode = mainNode[subId];

      // 필요한 데이터를 오브젝트로 생성
      const extractedData = {
        sentenceId: subNode.sentences[0]?.sentence_id ?? null,
        isEdited: subNode.sentences[0]?.is_edited ?? null,
        concatenatedOriginalText: subNode.concatenatedOriginalText ?? '',
        concatenatedTranslatedText: subNode.concatenatedTranslatedText ?? '',
        concatenatedTranslatedTextKorean: subNode.concatenatedTranslatedTextKorean ?? '',
        concatenatedLatestText: subNode.concatenatedLatestText ?? '',
        concatenatedPolishedText: subNode.concatenatedPolishedText ?? '',
        translationColumnId: subNode.sentences[0]?.translation_column_id ?? null,

        isHovered: subNode.isHovered ?? false,
        isTooltipVisible: subNode.isTooltipVisible ?? false,
        loading: subNode.loading ?? false,
        isNotSaved: subNode.isNotSaved ?? false,
      };

      // 결과 배열에 추가
      result.push(extractedData);
    });
  });

  const temp = mergeArrayBySentenceId(result);

  if (target === 0) {
    projectText.value = temp; // projectText에 결과 배열을 저장
    copiedProjectText.value = structuredClone(projectText.value); // 비교용 번역문에 깊은 복사
  }

  if (target === 1) {
    compareProjectText.value = temp;
  }

  if (target === 2) {
    return temp;
  }

}

// 추출한 트리 데이터 sentence_id 기준으로 모아서 병합해서 오브젝트 배열 만들기
const mergeArrayBySentenceId = (arr) => {
  const mergedMap = {};

  arr.forEach(item => {
    const {
      sentenceId,
      concatenatedLatestText,
      concatenatedOriginalText,
      concatenatedTranslatedText,
      concatenatedTranslatedTextKorean
    } = item;

    if (!mergedMap[sentenceId]) {
      // 새로운 sentenceId를 발견한 경우, 해당 객체를 그대로 추가
      mergedMap[sentenceId] = {...item};
    } else {
      // 기존에 같은 sentenceId가 있다면 문자열 필드 병합
      const current = mergedMap[sentenceId];
      current.concatenatedLatestText += concatenatedLatestText || "";
      current.concatenatedOriginalText += concatenatedOriginalText || "";
      current.concatenatedTranslatedText += concatenatedTranslatedText || "";
      current.concatenatedTranslatedTextKorean += concatenatedTranslatedTextKorean || "";
    }
  });

  // 병합된 결과를 배열로 변환하여 반환
  return Object.values(mergedMap);
};

// 원분, 번역문, 역 번역문 vs 비교 번역문 트리 생성
const fetchSentences = async (type) => {
  try {
    sentences.value = await store.getProjectTexts(projectId, type);

    if (type === 0) {
      const treeData = buildTree();
      extractData(treeData, type);
    }

    if (type === 1) {
      const treeData = buildTree();
      extractData(treeData, type);
    }

  } catch (e) {
    console.error("fetchSentence", e);
  }
}

// 한 줄 업데이트
async function updateSingleSentence(pt) {
  try {
    const setPath = `${ENDPOINTS.UPDATE_PROJECT_TEXTS}${projectId}/${pt.sentenceId}`;
    const rb = {
      translation_column_id: pt.translationColumnId,
      latest_text: pt.concatenatedLatestText,
    }
    const res = await api.patch(setPath, rb);
    if (res.data.result) {
      return res.data.result;
    }
  } catch (e) {
    console.error("updateSingleSentence", e);
  }
}

// 한 줄 업데이트 한 다음에 가져오기
async function getSingleSentence(pt) {
  try {
    // const setQuery = `${ENDPOINTS.GET_PROJECT_TEXTS}${projectId}/${pt.sentenceId}?translation_column_id=${pt.translationColumnId}`;
    const setQuery = `${ENDPOINTS.GET_PROJECT_TEXTS}${projectId}/${pt.sentenceId}`;
    const res = await api.get(setQuery);

    if (res.data) {
      const temp = mergeArrayBySentenceId(res.data.result);

      for (let i = 0; i < projectText.value.length; i++) {
        if (projectText.value[i].sentenceId === temp.sentenceId) {
          projectText.value[i] = temp;
          return
        }
      }

      copiedProjectText.value = structuredClone(projectText.value);
      pt.isNotSaved = false;
    }
  } catch (e) {
    console.error("getSingleSentence", e);
  }
}

// 윤문 텍스트 전체 반영하기
async function updateParaphraseTextAll() {
  try {
    if (flags.value.paraphraseAll) {
      alert("윤문 내용을 반영 중입니다.");
      return
    }

    flags.value.paraphraseAll = true;

    let count = 0;

    for (let i = 0; i < projectText.value.length; i++) {
      projectText.value[i].concatenatedLatestText = projectText.value[i].concatenatedPolishedText;
    }

    for (let i = 0; i < projectText.value.length; i++) {
      const isSuccess = await updateSingleSentence(projectText.value[i]);

      if (isSuccess) {
        await getSingleSentence(projectText.value[i]);
        count++;
      }
    }

    if (count === projectText.value.length) {
      flags.value.paraphraseAll = false;
    }

  } catch (e) {
    console.error("updateParaphraseTextAll", e);
  }
}

// 번역문에서 엔터 누르면 수정하고 저장, 쉬프트 엔터 누르면 줄 바꿈
async function handleKeyDown(event, pt) { // Handle Keydown event on textarea. mainNode: clonedProjectText
  try {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // 기본 줄바꿈 동작 방지

      const isSuccess = await updateSingleSentence(pt);

      event.target.blur();
      pt.isNotSaved = true;

      if (isSuccess) {
        await getSingleSentence(pt);
      }

    } else if (event.key === "Enter" && event.shiftKey) { // shift + enter 줄바꿈
    }
  } catch (e) {
    console.error("handleKeyDown error: ", e);
  }
}

// 용어집에서 엔터 누르면 수정하고 저장, 쉬프트 엔터 누르면 줄 바꿈
async function handleGloKeyDown(event, gd, index) { // Handle Keydown event on textarea. mainNode: cloned ProjectText
  try {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // 기본 줄바꿈 동작 방지
      event.target.blur(); // 포커스 벗어나기
      gloUpdateId.value = gd.id;

      flags.value.savingGlo = index;

      const rb = {
        translated_term_latest: gd.translated_term_latest
      }

      const res = await api.patch(`translation-projects/${gd.translation_project_id}/glossary-data/${gd.id}`, rb)

      if (res.data.result) {
        const tempGlo = await store.getGlossaryData(projectId);
        copiedGlossaryData.value = structuredClone(tempGlo);

        const targetGlo = tempGlo.find(item => item.id === gloUpdateId.value);
        const finalTarget = glossaryData.value.find(item => item.id === gloUpdateId.value);

        if (finalTarget) {
          finalTarget.translated_term_latest = targetGlo.translated_term_latest;
          gloUpdateId.value = 0;
        }

        flags.value.savingGlo = -1;

        flags.value.applyEditedGlossaryData = true;
      }
    } else if (event.key === "Enter" && event.shiftKey) { // shift + enter 줄바꿈

    }
  } catch (e) {
    console.error("handleKeyDown error: ", e);
  }
}

function checkNotSavedStatus(index, pt) {
  pt.isNotSaved = pt.concatenatedLatestText !== copiedProjectText.value[index].concatenatedLatestText;
}

async function initProject() {
  try {
    // await fetchSentences(0);
    sentences.value = [];
    sentences.value = await store.getProjectTexts(projectId);

    if (sentences.value.length > 0) {
      const treeData = buildTree();
      extractData(treeData, 0);
    }

    await getGloDataInfinite();


    formData.value.projectFieldId = projectBasicInfo.value.project_field_id;
    formData.value.translationModelId = projectBasicInfo.value.translation_model_id;
    formData.value.tamseCode = projectBasicInfo.value.tamse_code;
    formData.value.publicPromptId = projectBasicInfo.value.translation_model_id;

    if (!!store.publicPromptListDefault) {
      const payload = {
        translationProjectFieldId: formData.value.projectFieldId,
        translationModelId: formData.value.translationModelId,
        tamseCode: formData.value.tamseCode,
        promptTypeId: 1
      }
      await store.getPublicPromptListDefault(payload);
    }

    if (projectBasicInfo.value.translation_model_comparing_id !== null) {
      flags.value.compareEngineOnOff = true;
      compareEngineId.value = projectBasicInfo.value.translation_model_comparing_id;
      const treeData = buildTree();
      extractData(treeData, 1);
    }

  } catch (e) {
    console.error("initProject", e);
  } finally {
    await setInitialHeights();
  }
}

async function getLoadingProgress() {
  try {
    const res = await api.get(ENDPOINTS.GET_NEW_PROJECT_LOADING + projectId);

    currentProgressNum.value = res.data.percentage;

    if (res.data.percentage < 100) {
      timeoutId.value = setTimeout(async () => {
        await getLoadingProgress();
      }, 3000);
    }

    if (res.data.percentage === 100) {
      await initProject();
      clearTimeout(timeoutId.value);
      currentProgressNum.value = 0;
      return true;
    }
  } catch (e) {
    console.error("getLoadingProgress error:", e);
    return false;
  }
}

async function deleteProject(yn) { // false: cancel, true: delete
  try {
    if (!yn) {
      const target = document.querySelector('.confirm_container');
      target.classList.add('slide_up');

      setTimeout(() => {
        target.classList.remove('slide_up');
        flags.value.deleteProject = yn;
      }, 300)
      return
    }

    const isSuccess = await store.deleteProject(projectId);

    if (isSuccess) {
      alert('프로젝트가 삭제 되었습니다.');
      await router.push({name: 'ProjectList'});
    }
  } catch (e) {
    console.error("deleteProject", yn);
  }
}

async function stopTranslating(yn) { // true: 번역 중지
  try {
    if (!yn) {
      const target = document.querySelector('.confirm_container');
      target.classList.add('slide_up');

      setTimeout(() => {
        target.classList.remove('slide_up');
        flags.value.cancelTranslating = yn;
      }, 300)
      return
    }

    const isSuccess = await cancelTranslationProgress();

    if (isSuccess) {
      projectBasicInfo.value.is_cancelled = true;
      flags.value.translateAgain = false;

      const target = document.querySelector('.confirm_container');
      target.classList.add('slide_up');

      setTimeout(() => {
        target.classList.remove('slide_up');
        flags.value.cancelTranslating = false;
      }, 300)

      stopTranslationTimer();
    }
  } catch (e) {
    console.error("stopTranslating", yn);
  }
}

async function stopTranslating2() { // #TO DO_001: stopTranslating() 과 합칠 수 있는 방법 연구
  try {
    const isSuccess = await cancelTranslationProgress();
    if (isSuccess) {
      flags.value.cancelTranslating = false;
      stopTranslationTimer();
      projectBasicInfo.value = await store.getProjectInfo(projectId);
    }
  } catch (e) {
    console.error("stopTranslating2", e);
  }
}

const cancelTranslationProgress = async () => {
  try {
    const res = await api.post(ENDPOINTS.CANCEL_TRANSLATION_PROGRESS, {translation_project_id: projectId});
    return res.data.result;
  } catch (e) {
    console.error("cancelTranslationProgress", e);
  }
}

function checkTranslationPartialLoading(pt) {
  pt.isHovered = !!pt.loading;
}

// 번역문 한 줄 새로 생성
async function createTranslationPartial(pt) {
  try {
    pt.loading = true;

    const rb = {
      translation_project_id: projectId,
      translation_column_id: pt.translationColumnId,
      sentence_id: pt.sentenceId,
      translation_model_id: projectBasicInfo.value.translation_model_id
    }

    const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_PARTIAL, rb);

    if (res.data.result) {
      const isSuccess = await getSentenceForPartialBtn(pt);

      if (isSuccess) {
        pt.loading = false;
      }
    }
  } catch (e) {
    console.error("createTranslationPartial error: ", e);
  }
}

// 전체 재번역 type => 0: 번역문, 1: 비교 번역문
async function createTranslationAll(type) {
  try {
    if (flags.value.loadingCompareEngine) {
      return
    }

    let rb = {};

    if (type === 0) { // 번역문
      rb = {
        translation_project_id: projectId,
        translation_column_id: type,
        translation_model_id: projectBasicInfo.value.translation_model_id
      }

      const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_ALL, rb);

      if (res.data.result) {
        sentences.value = [];
        sentences.value = await store.getProjectTexts(projectId, type);

        if (sentences.value.length > 0) {
          projectText.value = [];
          copiedProjectText.value = [];
          tree.value = {};
          copiedTree.value = {};

          const treeData = buildTree();
          extractData(treeData, type);
          copiedProjectText.value = structuredClone(projectText.value);
        }
        await setInitialHeights();
        return true;
      }
    }

    if (type === 1) { // 비교 번역문
      const isSuccess = await updateProjectData();

      rb = {
        translation_project_id: projectId,
        translation_column_id: type,
        translation_model_id: compareEngineId.value
      }

      const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_ALL, rb);

      if (res.data) {
        sentences.value = [];
        sentences.value = await store.getProjectTexts(projectId, type);

        if (sentences.value.length > 0) {
          tree.value = {};
          copiedTree.value = {};

          const treeData = buildTree();
          extractData(treeData, type);
        }

        if (isSuccess) {
          await checkLoadingProgress(type);
        }

        await setInitialHeights();
        return true;
      }
    }
  } catch (e) {
    console.error(`createTranslationAll ${type}`, e);
  }
}

async function updateProjectData() {
  try {
    let rb = {
      public_prompt_id: projectBasicInfo.value.public_prompt_id,
      request_sentence_range: projectBasicInfo.value.request_sentence_range,
    }

    if (compareEngineId.value !== 0) {
      rb.translation_model_comparing_id = compareEngineId.value;
    }

    if (projectBasicInfo.value.have_private_prompt) {
      rb.have_private_prompt = true;
      rb.document_type_id = projectBasicInfo.value.document_type_id;
    }

    const res = await api.patch(ENDPOINTS.UPDATE_PROJECT_DATA + projectId, rb);

    if (res.data.result) {
      return res.data.result;
    }
  } catch (e) {
    console.error("updateProjectData", e);
  }
}

async function checkLoadingProgress(type) {
  try {
    if (type === 0) {
      projectText.value = [];
    }

    if (type === 1) {
      flags.value.loadingCompareEngine = true;
      compareProjectText.value = [];
    }

    const res = await api.get(ENDPOINTS.GET_NEW_PROJECT_LOADING + projectId);

    currentProgressNum.value = res.data.percentage;

    if (res.data.percentage < 100) {
      timeoutId.value = setTimeout(async () => {
        await checkLoadingProgress(type);
      }, 3000);
    }

    if (res.data.percentage === 100) {
      clearTimeout(timeoutId.value);
      currentProgressNum.value = 0;
      // return true;

      sentences.value = [];
      sentences.value = await store.getProjectTexts(projectId, type);

      if (sentences.value.length > 0) {
        if (type === 0) {
          projectText.value = [];
        }

        if (type === 1) {
          compareProjectText.value = [];
        }

        tree.value = {};
        copiedTree.value = {};
        compareTree.value = {};

        const treeData = buildTree();
        extractData(treeData, type);
      }

      if (type === 1) {
        flags.value.loadingCompareEngine = false;
      }

      await setInitialHeights();
    }
  } catch (e) {
    console.error("checkLoadingProgress", e);
  }
}

// 한 문장 가져오기
async function getSentenceForPartialBtn(pt) {
  try {
    const endpoint = `tc1-compatible/project-text-sentences/${projectId}/${pt.sentenceId}?translation_column_id=${pt.translationColumnId}`;

    const res = await api.get(endpoint);

    if (res.data) {
      const treeData = buildTree();
      extractData(treeData, 0);
      copiedProjectText.value = structuredClone(projectText.value);
      return true;
    }
  } catch (e) {
    console.error("getSentenceForPartialBtn", e);
  }
}

async function updateSentence(pt, updatedText) { // 한 문장 수정
  try {
    const rb = {
      translation_column_id: pt.translationColumnId,
      latest_text: updatedText,
    }

    const endpoint = `tc1-compatible/project-text-sentences/${projectId}/${pt.sentenceId}`;
    const res = await api.patch(endpoint, rb);

    if (res.data.result) {
      return true;
    }
  } catch (e) {
    console.error("updateSentence", e);
  }
}

async function applyEditedGloData() {
  try {
    const setEndpoint = `${ENDPOINTS.UPDATE_GLOSSARY_DATA}${projectId}/glossary-data/apply`;
    const res = await api.post(setEndpoint);

    if (res.data.result) {
      flags.value.applyEditedGlossaryData = false;

      await fetchSentences(0);
    }
  } catch (e) {
    console.error("applyEditedGloData", e);
  }
}

async function updatePrivatePrompt() {
  try {
    formData.value.promptContents = formData.value.promptContents === '' ? ' ' : formData.value.promptContents;
    const res = await api.patch(ENDPOINTS.UPDATE_PRIVATE_PROMPT + projectId, {prompt: formData.value.promptContents})

    if (formData.value.promptContents === ' ') {
      await api.patch(ENDPOINTS.UPDATE_PROJECT_DATA + projectId, {have_private_prompt: false});
    }

    return res.data.result;
  } catch (e) {
    console.error("updatePrivatePrompt", e);
  }
}

async function reTranslation() {
  try {
    flags.value.modalOnOff = false;
    flags.value.loadingCompareEngine = false;
    flags.compareEngineOnOff = false;

    tree.value = {};
    compareTree.value = {};

    projectText.value = [];
    copiedProjectText.value = [];

    projectBasicInfo.value.is_cancelled = false;

    let rb = {};

    await api.patch(ENDPOINTS.UPDATE_PROJECT_DATA + projectId, {have_private_prompt: true});
    const isSuccess = await updatePrivatePrompt();

    if (isSuccess) {
      rb = {
        translation_project_id: projectId,
        translation_column_id: 0,
        translation_model_id: formData.value.translationModelId
      }

      const res_0 = await api.post(ENDPOINTS.CREATE_TRANSLATION_ALL, rb);

      if (res_0.data.result) {
        await checkLoadingProgress(0);

        flags.value.compareEngineOnOff = false;

        await setInitialHeights();
      }
    }

  } catch (e) {
    console.error("reTranslation", e);
  }
}

const handleMouseEnter = (subNode) => {
  subNode.isHovered = true;
  subNode.isTooltipVisible = true;
};

const handleMouseLeave = (subNode) => {
  subNode.isHovered = false;
  subNode.isTooltipVisible = false;
};

const setInitialHeights = async () => {
  const textarea = textareaRefs.value;
  const gloTextarea = gloTextareaRefs.value;
  await nextTick(); // DOM 렌더링 완료 후 실행

  textarea.forEach((textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  });

  gloTextarea.forEach((gloTextarea) => {
    if (gloTextarea) {
      gloTextarea.style.height = "auto";
      gloTextarea.style.height = `${gloTextarea.scrollHeight}px`;
    }
  });
};

async function copyToClipboard(text, index) {
  try {
    if (!text) return;

    copiedGloIndex.value = index;

    if (gloCopyTimeout.value) clearTimeout(gloCopyTimeout.value);
    gloCopyTimeout.value = setTimeout(async () => {
      copiedGloIndex.value = null;
    }, 3000);

    const targetText = splitReferenceString(text); // 괄호 안의 출처 제거
    await navigator.clipboard.writeText(targetText);

  } catch (err) {
    console.error('복사 실패:', err);
  }
}

function splitReferenceString(input) {
  const parts = input.split(/\((?!.*\()/); // 마지막 '(' 기준으로 스플릿
  const firstPart = parts[0].trim(); // 앞부분 문자열 가져오기

  return firstPart.replace(/\s+$/, ''); // 마지막 공백 제거 후 리턴
}

async function showModal() {
  try {
    flags.value.modalOnOff = true;
    await getPrivatePromptData();
  } catch (e) {
    console.error("showModal", e);
  }
}

// 입력한 커스텀 프롬프트 내용 받아오기
async function getPrivatePromptData() {
  try {
    const res = await api.get(ENDPOINTS.GET_PRIVATE_PROMPT_DATA + projectId);
    if (res.data) {
      formData.value.promptContents = res.data.result.prompt;
    }
  } catch (e) {
    console.error("getPrivatePromptData", e);
  }
}

// 비교 번역문 번역문에 복사 붙여넣기
async function copyToTranslatedText(cpt, pt) {
  pt.concatenatedLatestText = cpt.concatenatedLatestText;
}

async function copyToParaphrasedText(cpt, pt) {
  pt.concatenatedLatestText = cpt.concatenatedPolishedText;

  const isSuccess = await updateSingleSentence(pt);

  if (isSuccess) {
    await getSingleSentence(pt);
  }
}

const isGloNotSaved = (gd, index) => {
  if (copiedGlossaryData.value[index].translated_term_latest === null) {
    return gd.translated_term_latest !== gd.translated_term;
  } else {
    return gd.translated_term_latest !== copiedGlossaryData.value[index].translated_term_latest;
  }
}

const isRunning = ref(false);
let timer = null;
let cancelTimer = null;

// 타이머 시작 함수
const startTranslationTimer = () => {
  if (!isRunning.value) {
    isRunning.value = true;
    flags.value.cancelTranslating = false; // 번역 취소 상태 초기화

    // 타이머 실행 (10초마다 실행)
    timer = setInterval(() => {
    }, 10 * 1000); // 10초

    // 10분 뒤 `cancelTranslating = true` 설정
    cancelTimer = setTimeout(() => {
      if (currentProgressNum.value < 100) {
        flags.value.cancelTranslating = true;
        stopTranslationTimer();
      }
    }, 10 * 60 * 1000); // 10분
  }
};

// 타이머 정지 함수
const stopTranslationTimer = () => {
  if (isRunning.value) {
    isRunning.value = false;
    clearInterval(timer);
    clearTimeout(cancelTimer);
    timer = null;
    cancelTimer = null;
  }
};

const getGloDataInfinite = async () => {
  try {
    const startPk = lastGloId.value === 1 ? lastGloId.value : lastGloId.value + 1;
    const res = await api.get(`${ENDPOINTS.GET_GLOSSARY_DATA_WITH_KEYSET}${projectId}?start_pk=${startPk}&limit_int=100`);

    if (res.data.result.length === 0) {
      gloObserver.disconnect();
    } else if (res.data.result.length > 0) {
      lastGloId.value = res.data.result[res.data.result.length - 1].id;

      let temp = res.data.result;

      temp.forEach((item) => {
        if (item.translated_term_latest === null) {
          item.translated_term_latest = item.translated_term;
        }
      })

      const mergedReferencesById = Object.values(
          temp.reduce((acc, item) => {
            if (!acc[item.id]) {
              acc[item.id] = {
                ...item,
                ...(item.reference !== null ? {reference: [item.reference]} : {})
              }
            } else {
              acc[item.id].reference = acc[item.id].reference || [];
              acc[item.id].reference.push(item.reference);
            }
            return acc;
          }, {})
      );

      glossaryData.value = glossaryData.value.concat(mergedReferencesById);
      copiedGlossaryData.value = structuredClone(glossaryData.value);

      setInitialHeights();
    }
  } catch (e) {
    console.error("getGloDataInfinite", e);
  }
}

watch(currentProgressNum, (newVal, oldVal) => {
  if (newVal > oldVal) {
    if (newVal === 50) {
      startTranslationTimer(); // 50이 되는 순간 타이머 시작
    }

    if (newVal === 100) {
      currentProgressNum.value = 100;
      stopTranslationTimer(); // 100이 되면 타이머 자동 종료
    } else {
      const step = () => {
        if (oldVal < newVal) {
          oldVal++; // 이전 값을 1씩 증가
          currentProgressNum.value = oldVal;
          requestAnimationFrame(step);
        }
      };
      step();
    }
  }
});

watch(() => flags.value.compareEngineOnOff, async (newVal) => {
      if (newVal === true) {
        await setInitialHeights();
      }
    }
);

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({name: 'Login'});
    return
  }

  // 프로젝트 기본 정보
  projectBasicInfo.value = await store.getProjectInfo(projectId);

  if (!!store.languageListDefault) { // 언어 id -> 이름 매칭
    const isSuccess = await store.getLanguageListDefault();
    if (isSuccess) {
      refinedProjectData.value.sourceLang = findLanguageName(projectBasicInfo.value.source_language_id);
      refinedProjectData.value.targetLang = findLanguageName(projectBasicInfo.value.target_language_id);
    }
  }

  if (!!store.translationModelListDefault) { // 번역 모델 id -> 이름 매칭
    const isSuccess = await store.getTranslationModelListDefault();
    if (isSuccess) {
      refinedProjectData.value.translationModel = findTranslationModelName(projectBasicInfo.value.translation_model_id);
    }
  }

  if (!!store.fieldListDefault) { // 분야 id -> 분야명 매칭
    const isSuccess = await store.getFieldListDefault();
    if (isSuccess) {
      refinedProjectData.value.field = findFieldName(projectBasicInfo.value.project_field_id);
    }
  }

  await getLoadingProgress();

  await setInitialHeights();

  gloObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            getGloDataInfinite(); // gloObserver 보일 때마다 실행
          }
        });
      },
      {threshold: 0.1} // 10%만 보여도 실행
  );

  if (gloObserverTarget.value) {
    gloObserver.observe(gloObserverTarget.value);
  }
  console.log(glossaryData.value)
});

onUnmounted(() => {
  if (gloObserver) {
    gloObserver.disconnect();
  }
});

</script>
<template>
  <div id="projectResult">
    <div v-if="flags.deleteProject" class="modal_wrapper">
      <div class="confirm_container">
        <p>프로젝트를 삭제 할까요?</p>
        <div class="btn_wrapper">
          <button class="btn size_28 grey" @click="deleteProject(false)">취소</button>
          <button class="btn size_28 red" @click="deleteProject(true)">삭제하기</button>
        </div>
      </div>
    </div>

    <div v-if="flags.cancelTranslating" class="modal_wrapper">
      <div class="confirm_container">
        <p class="cancel_translating">대용량 파일 사용으로 번역 소요 시간이 길어지고 있습니다.<br>번역 중지를 원하는 경우, '중단하기'를 선택해주세요.</p>
        <div class="btn_wrapper">
          <button class="btn size_28 grey" @click="stopTranslating(false)">계속하기</button>
          <button class="btn size_28 red" @click="stopTranslating(true)">중단하기</button>
        </div>
      </div>
    </div>

    <div class="sub_header">
      <div class="left" @click="router.push({ name: 'ProjectList' })">
        <i-chevron class="icon size_24 left primary"/>
        <h1 class="project_name">{{ projectBasicInfo.display_name }}</h1>
      </div>
      <div class="right">
        <i-trash class="icon size_16 black_1" @click="flags.deleteProject = !flags.deleteProject"/>
        <div class="download_btn" @mouseenter="flags.downloadHover = true;" @mouseleave="flags.downloadHover = false">
          <i-download class="icon size_16 black_1" :class="{'hovered' : flags.downloadHover }"/>
          <div class="download_hovered_wrapper" :class="{ 'download_hover': flags.downloadHover }"
               @mouseleave="flags.downloadHover = false">
            <p @click="store.downloadOriginalDocument(projectId, projectBasicInfo.display_name)">원본 파일</p>
            <p @click="store.downloadTranslationDocument(projectId, projectBasicInfo.display_name)">번역문 파일</p>
            <p @click="store.downloadGlossaryDocument(projectId, projectBasicInfo.display_name)">용어집 파일</p>
          </div>
        </div>
        <i-setting class="icon size_16 black_1" @click="showModal"/>
      </div>
    </div>

    <div class="contents_wrapper">
      <div class="main_contents_container">
        <div class="control_panel">
          <div class="language_info">
            <span class="src_language">{{ refinedProjectData.sourceLang }}</span>
            <i-chevron class="icon size_20 right"/>
            <span class="tgt_language">{{ refinedProjectData.targetLang }}</span>
          </div>

          <span class="tamse_zone">
            {{ projectBasicInfo.tamse_code }},
          </span>&nbsp;
          <span class="field_name">
            {{ refinedProjectData.field }} 분야
          </span>
          <div v-if="projectBasicInfo.is_cancelled" class="translation_cancelled">
          </div>

          <div class="engine_comparison">
            <span>엔진 비교</span>
            <div class="toggle" :class="{ 'on': flags.compareEngineOnOff }">
              <div class="circle" @click="flags.compareEngineOnOff = !flags.compareEngineOnOff"></div>
            </div>
          </div>
        </div>

        <div class="search_container">
          <i-search class="icon size_12 grey_3"/>
          <input id="searchProjectText" v-model="projectTextInput" placeholder="용어 검색"/>
          <div class="xmark_background" @click="projectTextInput = ''">
            <i-close class="icon size_12 grey_2"/>
          </div>
        </div>

        <div class="table_info">
          <p class="total_count">{{ projectText.length }}개의 문장</p>
          <div v-show="projectBasicInfo.is_cancelled">
            <img src="@/assets/img/warning.png" alt="warning"/>
            <span class="canceled_text">번역이 중지된 프로젝트입니다.</span>
            <button class="btn size_24 grey" @click="reTranslation"
                    @mouseenter="flags.translateAgain = !flags.translateAgain"
                    @mouseleave="flags.translateAgain = !flags.translateAgain">
              <span>다시 시도</span>
              <i-synchronize class="icon size_12 grey_1"/>
            </button>
          </div>
        </div>

        <div class="main_contents">
          <div class="main_row head">
            <div class="index">No.</div>
            <div class="original">원문</div>
            <div class="translation" :style="{ borderRadius: !flags.compareEngineOnOff ? '0 12px 0 0' : '' }">
              <span class="engine_name">{{ refinedProjectData.translationModel }}</span>
              <span>번역문</span>
              <div v-if="projectBasicInfo.source_language_id === 2 && projectBasicInfo.target_language_id === 1"
                   class="icon_wrapper" @mouseenter="flags.paraphraseAllTooltip = true"
                   @mouseleave="flags.paraphraseAllTooltip = false" @click="updateParaphraseTextAll()">
                <i-paraphrase v-if="!flags.paraphraseAll" class="icon size_12 white"/>
                <div v-else class="loading" style="width: 8px; height: 8px; padding: 2px;"></div>
                <p v-show="flags.paraphraseAllTooltip" class="tooltip">윤문 전체 반영하기</p>
              </div>
            </div>
            <div v-if="flags.compareEngineOnOff" class="comparison_engine">
              <div class="select_container">
                <select v-model="compareEngineId" @change="createTranslationAll(1)" class="comparison_engine_select">
                  <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">
                    {{ tmld.model_name }}
                  </option>
                </select>
                <i-chevron class="icon size_20 down grey_3"/>
              </div>
              <span v-show="!flags.loadingCompareEngine">비교 번역문</span>
              <div v-show="flags.loadingCompareEngine" class="loading" style="width: 10px; height: 10px; padding: 3px;">
              </div>
              <i-download class="icon download size_12 white"
                          @click="store.comparingTranslationDocument(projectId, projectBasicInfo.display_name)"/>
            </div>
          </div>

          <div v-if="projectText.length === 0" class="loading_row">
            <p style="margin-bottom: 8px">{{ currentProgressNum }}%</p>
            <div class="loading"></div>
            <!--            what contents fit this area for who sit down and just wait to stop loading circle like a revolver? -->
            <button v-if="!projectBasicInfo.is_cancelled" class="btn size_20 primary translation_cancel_text"
                    @click="stopTranslating2">번역 중단하기
            </button>
            <button v-else disabled class="btn size_20 primary translation_cancel_text" style="margin-top: 12px">프로세스 중단
              중...
            </button>
          </div>

          <div v-else v-for="(pt, index) in filteredProjectText" :key="index" class="main_row">
            <span class="index">{{ store.modifyIndex(pt.sentenceId + 1) }}</span>
            <p class="original">{{ pt.concatenatedOriginalText }}</p>
            <div class="translation" :class="{'border_by_compare_engine' : !flags.compareEngineOnOff }"
                 @mouseenter="pt.isHovered = true" @mouseleave="checkTranslationPartialLoading(pt)">
              <span class="badge" :class="{
                edited: pt.isEdited || pt.concatenatedLatestText !== pt.concatenatedTranslatedText,
                not_saved: pt.concatenatedLatestText !== copiedProjectText[pt.sentenceId]?.concatenatedLatestText,
              }"></span>

              <div v-show="pt.isHovered" class="translate_partial_icon" @click="createTranslationPartial(pt)"
                   @mouseenter="handleMouseEnter(pt)" @mouseleave="handleMouseLeave(pt)">
                <i-synchronize v-if="!pt.loading" class="icon size_16 grey_1"/>
                <div v-else class="loading" style="width: 10px; height: 10px; padding: 3px;"></div>
                <p v-show="pt.isTooltipVisible" class="tooltip">다시 번역하기</p>
              </div>
              <textarea ref="textareaRefs" @input="autoResize(pt)" @keydown="handleKeyDown($event, pt)"
                        @change="checkNotSavedStatus(pt.sentenceId, pt)" class="translated_text"
                        :class="{ not_saved: pt.isNotSaved }"
                        v-model="pt.concatenatedLatestText"></textarea>
              <hr/>
              <p v-if="projectBasicInfo.source_language_id !== 2 && projectBasicInfo.target_language_id !== 1"
                 class="back_translated_text">
                {{ pt.concatenatedTranslatedTextKorean }}
              </p>
              <div v-else class="paraphrased_text" @mouseenter="flags.paraphraseCopy = pt.sentenceId"
                   @mouseleave="flags.paraphraseCopy = null">
                <div v-show="flags.paraphraseCopy === pt.sentenceId" class="variant_partial_icon">
                  <i-paraphrase v-if="flags.paraphraseCopy === pt.sentenceId" class="icon size_16 grey_1"
                                @click="copyToParaphrasedText(copiedProjectText[pt.sentenceId], pt)"
                                @mouseenter="flags.paraphraseCopyTooltip = pt.sentenceId"
                                @mouseleave="flags.paraphraseCopyTooltip = null"/>
                  <div v-else class="loading" style="width: 10px; height: 10px; padding: 3px;"></div>
                  <p v-show="flags.paraphraseCopyTooltip === pt.sentenceId" class="tooltip">기존 번역문에 반영하기</p>
                </div>
                <p style="margin-bottom: 4px">윤문 :</p>
                <p>{{ pt.concatenatedPolishedText }}</p>
              </div>
            </div>

            <div v-if="flags.compareEngineOnOff" class="comparison_engine"
                 @mouseenter="handleMouseEnter(compareProjectText[pt.sentenceId])"
                 @mouseleave="handleMouseLeave(compareProjectText[pt.sentenceId])">
              <div v-show="compareProjectText[pt.sentenceId]?.isHovered" class="variant_partial_icon">
                <i-variant v-if="!compareProjectText[pt.sentenceId]?.loading" class="icon size_16 grey_1"
                           @click="copyToTranslatedText(compareProjectText[pt.sentenceId], pt)"/>
                <div v-else class="loading" style="width: 10px; height: 10px; padding: 3px;"></div>
                <p v-show="compareProjectText[pt.sentenceId]?.isTooltipVisible" class="tooltip">기존 번역문에 반영하기</p>
              </div>
              <p class="translated_text">
                {{ compareProjectText[pt.sentenceId]?.concatenatedLatestText }}
              </p>
              <hr v-if="projectBasicInfo.source_language_id !== 2 && projectBasicInfo.target_language_id !== 1"/>
              <p class="back_translated_text">
                {{ compareProjectText[pt.sentenceId]?.concatenatedTranslatedTextKorean }}
              </p>
            </div>
          </div>

          <div v-if="flags.compareEngineOnOff && compareProjectText.length === 0" class="no_data">
            <p>비교 번역할 엔진을 선택하세요</p>

            <div class="select_container">
              <select v-model="compareEngineId" class="comparison_engine_select">
                <option value="0" disabled>엔진명</option>
                <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">
                  {{ tmld.model_name }}
                </option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>

            <button class="btn size_28 primary" :disabled="flags.loadingCompareEngine" @click="createTranslationAll(1)">
              <span v-show="!flags.loadingCompareEngine">결과 보기</span>
              <span v-show="flags.loadingCompareEngine" style="margin-right: 6px;">{{ currentProgressNum }}%</span>
              <span v-show="flags.loadingCompareEngine" class="loading" style="width: 16px; height: 16px;"></span>
            </button>
          </div>
        </div>
      </div>

      <!--용어집-->
      <div class="glo_wrapper">
        <div class="title_container">
          <div class="top">
            <h4>용어집</h4>
            <button v-show="flags.applyEditedGlossaryData" class="btn"
                    @mouseenter="flags.applyEditedGlossaryDataHovered = true"
                    @mouseleave="flags.applyEditedGlossaryDataHovered = false" @click="applyEditedGloData">
              <span>번역문에 반영</span>
              <i-synchronize class="icon size_16 blue" :class="{ 'rotation' : flags.applyEditedGlossaryDataHovered}"/>
            </button>
          </div>
          <p>번역문수정이 필요한 경우, 용어집을 먼저 수정 해주세요.</p>

          <div class="search_container">
            <i-search class="icon size_12 grey_3"/>
            <input id="searchProjectText" v-model="gloTextInput" placeholder="용어 검색"/>
            <div class="xmark_background" @click="gloTextInput = ''">
              <i-close class="icon size_12 grey_2"/>
            </div>
          </div>
        </div>

        <div class="table">
          <div class="th">
            <p>원문 용어</p>
            <p>번역된 용어</p>
          </div>
          <div v-for="(gd, index) in filteredGlossaryData" :key="index" class="tbody">
            <p class="src_glo td">{{ gd.original_term }}</p>
            <div class="tgt_glo td">
              <span v-if="flags.savingGlo === (gd.id - glossaryData[0].id)" class="badge loading"></span>
              <span v-else class="badge" :class="{
                edited: gd.translated_term_latest !== copiedGlossaryData[(gd.id - glossaryData[0].id)].translated_term ,
                not_saved: isGloNotSaved(gd, (gd.id - glossaryData[0].id)),
              }"></span>

              <textarea ref="gloTextareaRefs" v-model="gd.translated_term_latest" class="glo_textarea"
                        :class="{'not_saved' : isGloNotSaved(gd, (gd.id - glossaryData[0].id))}"
                        @keydown="handleGloKeyDown($event, gd, (gd.id - glossaryData[0].id))" @input="autoResizeGlo(gd)"
                        @focusin="flags.isGloFocused = (gd.id - glossaryData[0].id)" @focusout="flags.isGloFocused = null"></textarea>

              <div class="glo_recommend_wrapper" :class="{'no_glo_reference' : gd.reference?.length === 0 }">
                <span v-show="flags.isGloFocused === (gd.id - glossaryData[0].id)" class="focus_msg">엔터를 눌러 저장하세요</span>
                <div v-if="gd.reference?.length > 0" v-for="(gr, idx) in gd.reference" class="glo_recommend"
                     @click="copyToClipboard(gr, idx)">
                  <span>{{ gr }}</span>
                  <i-check v-if="copiedGloIndex === idx" class="icon size_12"/>
                  <i-copy v-else class="icon size_12"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="gloObserverTarget" ref="gloObserverTarget"></div>
      </div>
    </div>

    <!--    Modal-->
    <div v-show="flags.modalOnOff" class="modal_wrapper">
      <div class="modal_container">
        <div class="modal_header">
          <h3>커스텀 프롬프트 설정</h3>
          <i-close class="icon size_20 grey_2 close-button" @click="flags.modalOnOff = false"/>
        </div>

        <form class="form_group">
          <label for="engine">번역 엔진</label>
          <select id="engine" v-model="formData.translationModelId">
            <option value="" disabled>선택</option>
            <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">{{ tmld.model_name }}
            </option>
          </select>

          <!--분야-->
          <label for="field">분야 <span>{{ formData.projectFieldId }}</span></label>
          <select id="field" v-model="projectBasicInfo.project_field_id">
            <option value="" disabled>선택</option>
            <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
          </select>

          <label for="promptName">프롬프트(기업) <span>{{ formData.promptName }}</span></label>
          <select v-model="formData.promptContents">
            <option v-show=" formData.promptContents === '' " value="" disabled>프롬프트명을 선택하세요</option>
            <option v-show=" formData.promptContents === ' ' " value=" " disabled>프롬프트명을 선택하세요</option>
            <option v-for="pl in store.publicPromptListDefault" :key="pl" :value="pl.prompt">{{ pl.name }}</option>
          </select>

          <label for="promptContents">프롬프트 내용</label>
          <textarea v-model="formData.promptContents" placeholder="프롬프트 내용을 입력하세요"></textarea>
        </form>

        <div class="warning_container">
          <img src="@/assets/img/warning.png" alt="warning"/>
          <p>설정 변경 후 재번역 시, 번역문에 적용된 모든 수정 내용이 초기화됩니다.</p>
        </div>

        <button class="btn size_28 primary" @click="reTranslation">반영하기</button>
      </div>
    </div>
  </div>
</template>
