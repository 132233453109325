<script>
export default {
  name: "i-check",
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.76887 3.09393C8.95369 3.24179 8.98366 3.51149 8.8358 3.69631L5.40723 7.98203C5.26262 8.16278 5.00061 8.19605 4.81543 8.05716L3.10114 6.77144C2.91179 6.62943 2.87341 6.3608 3.01543 6.17144C3.15744 5.98209 3.42607 5.94371 3.61543 6.08573L4.9974 7.12221L8.16648 3.16086C8.31435 2.97603 8.58404 2.94607 8.76887 3.09393Z"
          fill="currentColor"/>
  </svg>

</template>

<style scoped>

</style>
