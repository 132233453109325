<script setup>
import { useRoute } from "vue-router";
import ILogout from "@/assets/img/icon/i-logout.vue";
import { useAuthStore } from "@/store/auth";
import { useStore } from "@/store";
import {computed} from "vue";

const props = defineProps({
  window: Object
});

const authStore = useAuthStore();
const store = useStore();
const route = useRoute();
const auth = computed(() => store.myData.role);
const nickname = computed(() => store.myData.nickname);
const feedbackUrl = `https://www.notion.so/dnbdivision/449c43ac2607452b85313e1622466666?v=a3855b15bac349cdb07ed6014d6597a6`;

function logout() {
  if (!confirm("로그아웃 하시겠습니까?")) {
    return;
  }

  authStore.logout();
}

</script>

<template>
  <div class="header">
    <div class="left">
      <h1>
        <router-link to="/projects/list">
          <img src="../assets/img/logo.svg" alt="logo" />
        </router-link>
      </h1>
      <div v-if="authStore.isAuthenticated" class="menu">
        <router-link to="/projects/list">프로젝트 리스트</router-link>
        <div class="bottom_line" :class="{ 'selected': route.path === '/projects/list' }"></div>
      </div>
      <div v-show="authStore.isAuthenticated && auth === 'ADMIN'" class="menu">
        <router-link to="/admin">관리자페이지</router-link>
        <div class="bottom_line" :class="{ 'selected': route.path.includes('admin') }"></div>
      </div>
      <div v-show="authStore.isAuthenticated && auth === 'ADMIN' || store.myData.nickname === '위현복' || store.myData.nickname === '박건규' " class="menu">
        <router-link to="/text-editor">법률 번역기</router-link>
        <div class="bottom_line" :class="{ 'selected': route.path.includes('text-editor') }"></div>
      </div>
    </div>

    <div class="right">
      <a :href="feedbackUrl">피드백 리포트</a>
      <div v-if="route.path !== '/login' && authStore.userId" class="logout">
        <span>{{ nickname }}님</span>
        <i-logout class="icon size_12" @click="logout" />
      </div>
    </div>
  </div>
</template>
