<script>
export default {
  name: "i-terminal"
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/terminal">
      <g id="vector">
        <path
            d="M6.04042 5.45952C5.6499 5.06899 5.01673 5.06899 4.62621 5.45952C4.23568 5.85004 4.23568 6.48321 4.62621 6.87373L8.9191 11.1666L4.62621 15.4595C4.23568 15.85 4.23568 16.4832 4.62621 16.8737C5.01673 17.2643 5.6499 17.2643 6.04042 16.8737L11.0404 11.8737C11.4309 11.4832 11.4309 10.85 11.0404 10.4595L6.04042 5.45952Z"
            fill="currentColor"/>
        <path
            d="M12 16.8333C11.4477 16.8333 11 17.281 11 17.8333C11 18.3856 11.4477 18.8333 12 18.8333H18.6667C19.219 18.8333 19.6667 18.3856 19.6667 17.8333C19.6667 17.281 19.219 16.8333 18.6667 16.8333H12Z"
            fill="currentColor"/>
      </g>
    </g>
  </svg>

</template>

<style scoped>

</style>
